/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import ArticleTeaser from "../components/entities/article/articleTeaser";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";

const ArticleByTopicIndex = (props) => {
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  const bgImage = convertToBgImage(
    props.data.heroImage.childImageSharp.gatsbyImageData
  );
  return (
    <Layout type="story-index-outer">
      <SEO title="AllstarCNC Stories" lang="en" description="All Star CNC Product provides solutions to hold down parts on a CNC Router with Gasket. MAX YOUR VAC by creating a clamp vs downdraft."></SEO>
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>AllstarCNC Stories</h1>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container className="article-index-container">
        <div
          sx={{
            padding: "0px 0px 0px 0px",
          }}
        >
          <ul
            sx={{
              padding: "80px 0px",
            }}
          >
            {props.data.article.nodes.map((article, index) => (
              <li
                key={index}
                sx={{
                  marginBottom: "90px",
                  listStyle: "none",
                  "&:last-child": {
                    marginBottom: "0px",
                  },
                }}
              >
                <ArticleTeaser article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div
          className="pager"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "90px",
          }}
        >
          <div
            sx={{
              marginRight: ["40px", "130px"],
            }}
            className="pager-previous-container"
          >
            {!isFirst && (
              <Link
                sx={{
                  fontSize: "1.5rem",
                  textDecorationThickness: "1px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/blog}/` + prevPage}
                rel="prev"
              >
                <span
                  sx={{
                    display: ["none", "block"],
                  }}
                >
                  Previous
                </span>
                <span
                  sx={{
                    display: ["flex", "none"],
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    sx={{
                      width: "15px",
                    }}
                    src={props.data.blueArrowLeft.publicURL}
                  />
                </span>
              </Link>
            )}
          </div>

          <ul
            className="pager-container"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              padding: "0px",
              margin: "0px",
            }}
          >
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                sx={{
                  listStyle: "none",
                }}
              >
                <Link
                  className={`pagination-number-${i + 1}`}
                  to={`/blog${i === 0 ? "" : "/" + (i + 1)}`}
                  activeClassName="pager-active"
                  sx={{
                    display: [
                      i + 1 == props.pageContext.currentPage ||
                      i + 1 == props.pageContext.currentPage + 1 ||
                      i + 1 == props.pageContext.currentPage + 2
                        ? "flex"
                        : "none",
                      i + 1 == props.pageContext.currentPage - 2 ||
                      i + 1 == props.pageContext.currentPage - 1 ||
                      i + 1 == props.pageContext.currentPage ||
                      i + 1 == props.pageContext.currentPage + 1 ||
                      i + 1 == props.pageContext.currentPage + 2
                        ? "flex"
                        : "none",
                    ],
                    width: "44px",
                    height: "44px",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                    fontSize: "1.5rem",
                    margin: "0px 5px",
                    "&.pager-active": {
                      borderRadius: "50%",
                      backgroundColor: "primary",
                      color: "white",
                    },
                  }}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
          </ul>
          <div
            sx={{
              marginLeft: ["40px", "130px"],
            }}
            className="pager-next-container"
          >
            {!isLast && (
              <Link
                sx={{
                  fontSize: "1.5rem",
                  textDecorationThickness: "1px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/blog/` + nextPage}
                rel="next"
              >
                <span
                  sx={{
                    display: ["none", "block"],
                  }}
                >
                  Next
                </span>
                <span
                  sx={{
                    display: ["flex", "none"],
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    sx={{
                      width: "15px",
                    }}
                    src={props.data.blueArrowRight.publicURL}
                  />
                </span>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default ArticleByTopicIndex;

export const articleIndexQuery = graphql`
  query articleByTopicListQuery($skip: Int!, $limit: Int!) {
    blueArrowRight: file(name: { eq: "Right-Blue-Caret" }) {
      publicURL
    }
    blueArrowLeft: file(name: { eq: "Left-Blue-Caret" }) {
      publicURL
    }
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    article: allSanityArticle(
      filter: { _id: { regex: "/^(?!draft).*$/" } }
      limit: $limit
      skip: $skip
      sort: { fields: [pinned, publish_date], order: [DESC, DESC] }
    ) {
      nodes {
        headline
        short_title
        pinned
        _rawIntroText(resolveReferences: { maxDepth: 10 })
        _rawBody(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        main_image {
          asset {
            gatsbyImageData(width: 1440)
          }
        }
      }
    }
  }
`;
