/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PortableTextComponent from "../../serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { toPlainTextTruncate } from "../../../utils/helpers";
import PlaceHolder from "../../../images/placeholder3.jpg";
const ArticleTeaser = ({ article }) => {
  return (
    <Link
      to={
        article.post_type === "article"
          ? "/articles/" + article.slug.current
          : "/blog/" + article.slug.current
      }
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        textDecoration: "none",
        alignItems: "center",
        ":hover": {
          opacity: ".7",
        },
      }}
    >
      <div
        className="article-teaser-image-container"
        sx={{
          width: ["100%", "100%", "406px"],
          marginRight: ["0px", "85px"],
        }}
      >
        {article.main_image ? (
          <div className="article-teaser-main-image">
            <GatsbyImage
              sx={{
                borderRadius: "8px",
              }}
              image={article.main_image.asset.gatsbyImageData}
              alt={article.headline}
            />
          </div>
        ) : (
          <div className="article-teaser-main-image">
            <img
              sx={{
                borderRadius: "8px",
              }}
              src={PlaceHolder}
            />
          </div>
        )}
      </div>
      <div
        className="article-teaser-text-container"
        sx={{
          width: ["100%", "100%", "calc(100% - 491px)"],
        }}
      >
        {article.post_type === "blog_post" && article._rawTopics && (
          <div
            sx={{
              fontSize: "1rem",
              display: "inline-block",
              marginRight: "25px",
              marginBottom: ["0px", "0px", "15px"],
              marginTop: ["20px", "20px", "0px"],
            }}
          >
            {article._rawTopics[0] && article._rawTopics[0].name}
          </div>
        )}
        {article.post_type === "blog_post" && (
          <div
            sx={{
              fontSize: "1rem",
              display: "inline-block",
              marginBottom: ["0px", "0px", "15px"],
              marginTop: ["20px", "20px", "0px"],
            }}
          >
            {article._updatedAt}
          </div>
        )}
        {article.short_title ? (
          <h2
            sx={{
              fontSize: "1.875rem",
              marginTop: ["20px", "20px", "0px"],
              marginBottom: "0px",
              lineHeight: "2.4rem",
            }}
          >
            {article.short_title}
          </h2>
        ) : article.headline ? (
          <h2
            sx={{
              fontSize: "1.875rem",
              marginTop: ["20px", "20px", "0px"],
              marginBottom: "0px",
              lineHeight: "2.4rem",
            }}
          >
            {article.headline}
          </h2>
        ) : (
          ""
        )}
        {article._rawIntroText ? (
          <div
            sx={{
              fontSize: "1.2rem",
              color: "textGray",
              lineHeight: "1.6rem",
              a: {
                color: "textGray",
              },
              p: {
                margin: ".6rem 0rem",
                lineHeight: "1.6rem",
              },
            }}
          >
            <PortableText
              value={article._rawIntroText}
              components={PortableTextComponent}
            />
          </div>
        ) : (
          <div
            sx={{
              fontSize: "1.2rem",
              color: "textGray",
              lineHeight: "1.6rem",
              a: {
                color: "textGray",
              },
              p: {
                margin: ".6rem 0rem",
                lineHeight: "1.6rem",
              },
            }}
          >
            <p>{toPlainTextTruncate(article._rawBody, 150)}</p>
          </div>
        )}

        <Link
          to={
            article.post_type === "article"
              ? "/articles/" + article.slug.current
              : "/blog/" + article.slug.current
          }
          sx={{
            textDecoration: "none",
            borderBottom: "4px solid",
            borderColor: "primary",
            fontSize: "1.125rem",
            marginTop: "7px",
            display: "inline-block",
          }}
          aria-label={article.headline && article.headline}
        >
          Read more
        </Link>
      </div>
    </Link>
  );
};
export default ArticleTeaser;
